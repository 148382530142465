
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    background-color: $bestzantaclawyers-blue;
    @apply mx-auto px-5  flex flex-col-reverse md:flex-row justify-between items-center;

    h2 {
        @apply text-lg mb-4;
        color: $bestzantaclawyers-grey-light;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        @include md {
            @apply text-2xl;
        }
    }
    li {
        color: $bestzantaclawyers-grey-light;
        margin: 6px 0;
        @apply text-sm;
        @include md {
            @apply text-base;
        }
    }
}
